<template>
    <div class="orders">
        <div class="mb-8">
            <h1>Commesse</h1>
        </div>

        <bxs-data-table
        ref="table"
        model-name="Order"
        resource="orders"
        id="table-orders-view"
        :headers="[
            { value: 'created_at' },
            { text: 'Numero', value: 'number_id' },
            { value: 'agent.email' },
            { text: 'Cliente', value: 'customer.business_name' },
            { text: 'Cliente P.IVA', value: 'customer.vat_code' },
            { text: 'Cliente authcode', value: 'customer_authcode' },
            { text: 'Descrizione/Oggetto', value: 'description' },
            { text: 'Ultima esportazione OS1', value: 'export_dates' },
            { text: 'Stato', value: 'status' },
            { text: 'Nr. Articoli', value: 'rows' },
            { text: 'Totale imponibile', value: 'cash.total_net' },
            { text: 'Totale lordo', value: 'cash.total_gross' }
        ]"
        :static-query="{
            spread: ['agent.ref']
        }"
        :static-filters="{
            status: '<>|draft'
        }">
            <template #append-toolbar="{ selected_ids }">
                <v-btn
                small
                color="primary"
                class="ml-2"
                @click="downloadExcellOS1()">Scarica tutti gli ordini excel (OS1)</v-btn>

                <v-btn
                small
                color="primary"
                class="ml-2"
                :disabled="!selected_ids.length"
                @click="downloadExcellOS1(selected_ids)">Scarica ordini selezionati excel (OS1)</v-btn>
            </template>

            <template #item.rows="{ value }">{{ value.length }}</template>

            <template #item.delivery.gross="{ value }">{{ value | toHumanPriceN }}</template>

            <template #item.payment.discount="{ value }">{{ value }}%</template>

            <template #item.cash.total_net="{ value }">{{ value | toHumanPriceN }}</template>

            <template #item.cash.total_gross="{ value }">{{ value | toHumanPriceN }}</template>

            <template #item.export_dates="{ value }">
                <span
                v-if="value && value.length > 0"
                v-tooltip="value[0]">{{ value[0] }}</span>
                <span>No</span>
            </template>

            <template #item.status="{ value }">
                <v-chip
                small
                :color="getStatusColor(value)">{{ value | toHumanOrderStatus }}</v-chip>
            </template>

            <template #item-actions="{ item }">
                <v-list-item :to="'/orders/' + item.id">
                    <v-list-item-content>
                        <v-list-item-title>Visualizza</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-icon>
                        <v-icon>chevron_right</v-icon>
                    </v-list-item-icon>
                </v-list-item>

                <v-list-item @click="print(item.id)">
                    <v-list-item-icon>
                        <v-icon>print</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Stampa PDF</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                v-if="item.status !== 'confirmed'"
                @click="changeStatus(item, 'confirmed')">
                    <v-list-item-icon>
                        <v-icon>check</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Conferma</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                v-if="item.status !== 'draft'"
                @click="changeStatus(item, 'draft')">
                    <v-list-item-icon>
                        <v-icon>reply</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Rimanda</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item @click="refreshCustomerAuthcode(item)">
                    <v-list-item-icon>
                        <v-icon>refresh</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Rigenera authcode cliente</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item @click="sendAuthCodeToCustomer(item)">
                    <v-list-item-icon>
                        <v-icon>send</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Notifica authcode al cliente</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                v-if="item.status !== 'canceled'"
                @click="changeStatus(item, 'canceled')">
                    <v-list-item-icon>
                        <v-icon>cancel</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Annulla e cestina</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </bxs-data-table>
    </div>
</template>

<script>
export default {
    name: 'orders',
    methods: {
        async downloadExcell (ids) {
            this.$store.commit('loading', true)
            let query = `?access_token=${this.$api.auth.access_token}`

            if (ids && ids.length > 0) {
                query += `&ids=${ids.join(',')}`
            }

            try {
                window.open(
                    `${this.$api.enums.origins.api}/v1/orders/downloads/excell${query}`,
                    '_blank'
                )
            } catch (err) {
                throw new Error(err.message || err.name)
            } finally {
                this.$store.commit('loading', false)
            }
        },
        async downloadExcellOS1 (ids) {
            this.$store.commit('loading', true)
            let query = `?access_token=${this.$api.auth.access_token}`

            if (ids && ids.length > 0) {
                query += `&ids=${ids.join(',')}`
            }

            try {
                window.open(
                    `${this.$api.enums.origins.api}/v1/orders/os1/downloads/excell${query}`,
                    '_blank'
                )

                this.$refs.table.getItems()
            } catch (err) {
                throw new Error(err.message || err.name)
            } finally {
                this.$store.commit('loading', false)
            }
        },
        async print (id) {
            this.$store.commit('loading', true)

            try {
                if (process.env.NODE_ENV === 'development') {
                    window.open(`http://localhost:5000/v1/orders/${id}/pdf?access_token=${this.$api.auth.token}`, '_blank')
                } else {
                    window.open(`https://admin.tfork.it/v1/orders/${id}/pdf?access_token=${this.$api.auth.token}`, '_blank')
                }
                // await this.$api.get('v1/orders/' + this.doc.id + '/pdf')
            } catch (err) {
            } finally {
                this.$store.commit('loading', false)
            }
        },
        getStatusColor (v) {
            if (v === 'draft') return 'info'
            else if (v === 'under_evaluation') return 'warning'
            else if (v === 'confirmed') return 'success'
            else if (v === 'canceled') return null
            return null
        },
        changeStatus (item, status) {
            let message = ''

            if (status === 'confirmed') message = 'confermare'
            else if (status === 'canceled') message = 'annullare'
            else if (status === 'draft') message = 'rimandare'

            this.$store.commit('SET_APP_DIALOG_CONFIRM', {
                title: 'Attenzione',
                text: `Vuoi ${message} la commessa <b>Nr. ${item.number_id}</b>?`,
                next: async () => {
                    try {
                        await this.$api.orders.modify(item.id, { status })
                        this.$refs.table.getItems()
                    } catch (err) {
                        throw new Error(err.message || err.name || err)
                    } finally {
                        this.$store.commit('loading', false)
                    }
                }
            })
        },
        refreshCustomerAuthcode (item) {
            this.$store.commit('SET_APP_DIALOG_CONFIRM', {
                title: 'Attenzione',
                text: `Vuoi rigenerare il codice autorizzazione cliente per la commessa nr. ${item.number_id}?`,
                next: async () => {
                    try {
                        await this.$api.patch('v1/orders/' + item.id + '/customers_auths_codes')
                        this.$refs.table.getItems()
                    } catch (err) {
                        throw new Error(err.message || err.name || err)
                    } finally {
                        this.$store.commit('loading', false)
                    }
                }
            })
        },
        sendAuthCodeToCustomer (item) {
            this.$store.commit('SET_APP_DIALOG_CONFIRM', {
                title: 'Attenzione',
                text: `Vuoi notificare il codice autorizzazione al cliente per la commessa nr. ${item.number_id}?`,
                next: async () => {
                    try {
                        await this.$api.sendMail({
                            from: 'noreply@tfork.it',
                            to: item.customer.email,
                            subject: `TforK | Codice visualizzazione commessa #${item.number_id}`,
                            template: 'order.customer',
                            data: {
                                url: `${process.env.NODE_ENV === 'development' ? 'http://localhost:8082' : 'https://agenti.tfork.it'}/o/${item.id}/c/${item.customer_authcode}`
                            }
                        })
                    } catch (err) {
                        throw new Error(err.message || err.name || err)
                    } finally {
                        this.$store.commit('loading', false)
                    }
                }
            })
        }
    }
}
</script>